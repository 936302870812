@import "../../../scss/base";

#main {
    display: flex;
    overflow: hidden;
    flex: 1 0;
    width: 100%;
    height: 100%;

    &.guest {
        padding-top: 55px;
    }

    #content {
        flex: 1 0;
        background-color: transparent;

        .pages {
            padding: 0px;
            background-color: $color-second;
            .page {
                height: 100%;
                overflow: hidden;
                display: flex;
                align-items: stretch;
                justify-content: flex-start;
            }
        }
    }
}

.nav {
    flex: 0 1;

    ul {
        list-style-type: none;
        padding: 0px;
        margin: 0px;

        li {
            a {
                display: block;
                padding: 10px 20px;
                text-decoration: none;
                color: $color-third;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                &:hover,
                &.active {
                    background-color: lighten($color-fourth, 90);
                }
            }

            &:nth-child(2n) {
                a {
                    background-color: lighten($color-fourth, 95);
                }
            }
        }
    }
}

.content {
    flex: 1 0;
    overflow: auto;

    & > div > .dx-widget,
    & > .dx-widget {
        margin: 10px;
    }
}

#root > .dx-drawer {
    padding-top: 53px;
}

#tilesContainer {
    overflow: auto;
}

.page {
    #tilesContainer {
        max-width: 100%;
        background-color: $color-third;
    }
}

#tilesContainer .tiles > div a svg.svg-inline--fa {
    padding: 5px;
}

.dx-drawer.not-logged-in {
    .dx-drawer-panel-content {
        width: 0px !important;
    }
}

.tilesContainerWrapper {
    height: 100%;
    width: 100%;
    background-color: $color-third;
    display: flex;
    justify-content: center;
}
