#tilesContainer .tiles > div a {
    display: block;
    width: 134px;
    height: 134px;
    border-radius: 15px 5px 15px 5px;
    background-color: var(--color-tiles);
    margin-bottom: 8px;
    float: left;
    text-align: center;
    color: var(--color-text);
    text-decoration: none;
    padding: 22px;
    font-family: Lato, sans-serif;
    /* box-shadow:  #dedede 4px 4px 2px;*/
    box-shadow: #636363 4px 4px 2px;
}
#main #content .pages #dishes.page .dishesList .dx-list-item {
    border-radius: 15px 5px 15px 5px;
}
#main #content .pages #dishes.page .dishesList .dx-list-group {
    border-radius: 15px 5px 15px 5px;
}

.tilesContainerWrapper {
    background-color: var(--color-tiles-background);
}

#tilesContainer .tiles header {
    color: var(--color-text);
}

.page #tilesContainer {
    background-color: var(--color-background);
}

#main #content .pages #dailyOrders.page .dailyOrdersList {
    background-color: var(--color-background);
}

#tilesContainer .tiles > div a {
    display: block;
    width: 134px;
    height: 134px;
    border-radius: 15px 5px 15px 5px;
    background-color: var(--color-tiles);
    margin-bottom: 8px;
    float: left;
    text-align: center;
    color: var(--color-text);
    text-decoration: none;
    padding: 22px;
    font-family: Lato, sans-serif;
    box-shadow: #636363 4px 4px 2px;
}
#main #content .pages #dishes.page .dishesList .dx-list-group {
    border-radius: 15px 5px 15px 5px;
}

#main {
    border-radius: 15px 5px 15px 5px;
}

#main .dx-checkbox-indeterminate .dx-checkbox-icon::before {
    background-color: transparent;
}

#main .indeterminate-row {
    .dx-treelist-checkbox-size.dx-checkbox-checked .dx-checkbox-icon {
        font-size: 10px;
    }

    .dx-checkbox-checked .dx-checkbox-icon::before {
        content: "";
        position: absolute;
        display: block;
        width: 14px;
        top: 50%;
        margin-top: -8px;
        left: 50%;
        margin-left: -7px;
    }
}

.dx-overlay-wrapper .dx-treeview .dx-treeview-node,
.dx-overlay-wrapper .dx-treeview .dx-treeview-node.dx-state-focused {
    padding-left: 15px;
}

.dx-overlay-wrapper
    .dx-treeview-item-without-checkbox.dx-state-focused
    > .dx-treeview-item
    .dx-treeview-toggle-item-visibility {
    color: var(--color-text);
}

.dx-overlay-wrapper .dx-treeview .dx-treeview-expander-icon-stub {
    display: none;
}
