﻿@import "variables";
@import url("https://fonts.googleapis.com/css?family=Lato:100,100i,300,300i,400,400i,700,700i,900,900i");

* {
    box-sizing: border-box;
}

body {
    background-color: $color-third;
    font-family: "Lato", sans-serif;
}

#root {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    height: 100vh;
}

.nav {
    background-color: $color-second;
    flex: 0 1;

    nav {
        height: 100%;
        width: 100%;
        overflow-y: auto;
    }
}

#main {
    #content {
        background-color: $color-second;
    }

    &.home {
        #content {
            background-color: transparent;
        }
    }
}

.buttonDialog {
    p {
        margin-top: 0px;
        // font-weight: bold;
    }

    .buttons {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

.draggable {
    cursor: grab;
}

@keyframes blink {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
}

.blink {
    animation-name: blink;
    animation-duration: 0.5s;
    animation-iteration-count: infinite;
}

.doubleArrow {
    margin-top: -4%;
    margin-left: -3%;
}

span {
    overflow-wrap: anywhere;
    hyphens: auto;
    -ms-hyphens: auto;
}

.fix-checkbox {
    &::before {
        display: none !important;
    }
}
.dx-datagrid {
    .dx-toolbar {
        .dx-button {
            border-radius: 4px;
            .dx-button-content {
                background-color: var(--color-main);
                color: white;
                border-radius: 4px;
                .dx-state.hover {
                    background-color: var(--color-main-darken-5);
                }
            }
        }
        .dx-button .dx-icon {
            color: white;
        }
        .dx-button-normal .dx-state-hover .dx-button-content {
            background-color: var(--color-main-darken-5);
        }
    }
}

.dx-button-has-icon .dx-icon.dx-icon-copy {
    line-height: 23px;
}
